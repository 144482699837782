


































































































































































/*
import * as fs from 'fs'
import * as path from 'path'
*/
import {
  createComponent,
  ref,
  computed,
  watch,
  SetupContext
} from '@vue/composition-api'
import moment from 'moment'
import { Device, Call } from '@twilio/voice-sdk'

const TWILIO_ENVIRONMENT = '/hayama_dev'
const TWILIO_BASE_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/'
const TWILIO_TOKEN_URL = `${TWILIO_BASE_URL}getToken${TWILIO_ENVIRONMENT}`
const TWILIO_RECORDON_URL = `${TWILIO_BASE_URL}recordOn${TWILIO_ENVIRONMENT}`
const TWILIO_DELETEVOICE_URL = `${TWILIO_BASE_URL}deleteVoiceRecord${TWILIO_ENVIRONMENT}`
const TWILIO_DOWNLOADVOICE_URL = `${TWILIO_BASE_URL}downloadVoiceRecord${TWILIO_ENVIRONMENT}`
const TWILIO_QUEUE_URL = `${TWILIO_BASE_URL}queueCall${TWILIO_ENVIRONMENT}`
const TWILIO_DISABLE_URL = `${TWILIO_BASE_URL}disableClient${TWILIO_ENVIRONMENT}`

export default createComponent({
  setup(_props: any, context: SetupContext) {
    const device = ref<Device | null>(null)
    // To ignore phone call while initializing
    context.root.$store
      .dispatch('setOperatorAvailability', {
        available: false
      })
      .then((id) => {
        console.log('Setup Voice SDK ...')
        return context.root.$axios.$get(`${TWILIO_TOKEN_URL}?id=${id}`)
      })
      .then((data) => {
        return new Promise((resolve: any, reject) => {
          // device 作成
          console.log('Token setup (registering)')

          device.value = new Device(data.token, {
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
            edge: `tokyo`
          })
          console.log('Token setup completed.')
          device.value.on('registered', () => {
            console.log('Twilio Voice SDK Device Ready!')
            resolve()
          })

          device.value.on('error', (error: any) => {
            console.log('Twilio.Device Error: ' + error.message)
            if (error.code === 31205 && context.root.$store.state.auth.user) {
              context.root.$axios
                .$get(
                  `${TWILIO_TOKEN_URL}?id=${context.root.$store.state.auth.user.id}`
                )
                .then((data) => {
                  device.value!.updateToken(data.token)
                })
            } else {
              reject(error)
            }
          })

          device.value.on('incoming', (call: Call) => {
            console.log('Incoming connection from ' + call.parameters.From)
            call.on('accept', (_call: Call) => {
              console.log('Successfully established call!')
              twilioConnection.value = _call
              // 再開の場合はフラグを off にする
              isResuming.value = false

              // 常時録音
              if (autoRecording) {
                console.log(' Automatic recoding start.')
                startRecording()
              }
            })

            call.on('disconnect', (_call: Call) => {
              console.log('Call ended.')
              if (twilioConnection.value) {
                saveConnection.value = twilioConnection.value
              }
              isIncoming.value = false
              twilioConnection.value = null
              context.root.$store.commit('updatePhoneNumber', {
                phoneNumber: phoneNumber.value
              })
              if (isRecording.value) {
                isRecording.value = false
                dialog.value = true
              } else {
                phoneNumber.value = ''
              }
            })

            call.on('cancel', (_call: Call) => {
              if (
                phoneNumber.value !== call.parameters.From.replace('+81', '0')
              ) {
                console.log('通話中の番号ではありません')
                return
              }
              console.log('Call canceled.')
              isIncoming.value = false
              twilioConnection.value = null
              // 2023-08-01 相手先名を非表示
              context.emit('callDisplayPartnerNames', false)
            })

            isIncoming.value = true
            twilioConnection.value = call
            phoneNumber.value = call.parameters.From.replace('+81', '0')

            // 2023-07-24 受電時の処理を外部に定義
            context.emit('callReceivingTelephone', phoneNumber.value)
          })

          device.value.on('tokenWillExpire', () => {
            if (context.root.$store.state.auth.user) {
              context.root.$axios
                .$get(
                  `${TWILIO_TOKEN_URL}?id=${context.root.$store.state.auth.user.id}`
                )
                .then((data) => {
                  device.value!.updateToken(data.token)
                })
            }
          })

          device.value.register()
        })
      })
      .then(() => {
        // Check remaining queue
        return context.root.$store.dispatch('getOperator')
      })
      .then((data) => {
        if (data.queued !== undefined) {
          if (data.queued && data.callSid) {
            console.log('This user has a queued call.')
            isQueued.value = true
          } else {
            console.log('This user has been readyed.')
            return context.root.$store.dispatch('setOperatorAvailability', {
              available: true
            })
          }
        } else {
          console.log('getOperator data not found.')
          return context.root.$store.dispatch('setOperatorAvailability', {
            available: true
          })
        }
      })
      .catch(function(err) {
        console.log('faild setup twilio')
        console.log(err)
      })
      .finally(() => {
        // 離席ボタンを押せるようにする
        setTimeout(() => {
          isSavingOperatorAvailability.value = false
        }, 1000)
      })

    // ダウンロード待機フラグ
    const isWaitingDownload = ref<boolean>(false)
    // 常時録音変数
    const autoRecording = process.env.autoRecording === 'true'

    const twilioConnection = ref<Call | null>(null)
    const saveConnection = ref<Call | null>(null)
    const isIncoming = ref<boolean>(false)
    const isOutgoingOpened = ref<boolean>(false)
    const isTalking = computed<boolean>(() => {
      if (twilioConnection.value && twilioConnection.value!.parameters) {
        if (twilioConnection.value!.parameters!.CallSid) {
          isOutgoingOpened.value = true
        }
      } else {
        isOutgoingOpened.value = false
      }
      return !isIncoming.value && isOutgoingOpened.value
    })
    const serviceAreaName = ref<string>('')
    const phoneNumber = ref<string>('')
    const savePhoneNumber = ref<string>('')
    const isOutgoingCall = ref<boolean>(false)
    const isInvalidPhoneNumber = computed(() => {
      const number = `+81${phoneNumber.value
        .replace(/[^\d]/g, '')
        .replace(/^0/, '')}`
      return (
        !/^[\d+\-() ]+$/.test(phoneNumber.value) ||
        (number.length !== 13 && number.length !== 12)
      )
    })
    watch(phoneNumber, () => {
      if (!phoneNumber.value) {
        isOutgoingCall.value = false
        serviceAreaName.value = ''
      }
      if (isIncoming.value) {
        context.root.$axios
          .$get(`/users/servicearea?phone=${phoneNumber.value}`)
          .then((area_name: string) => {
            serviceAreaName.value =
              phoneNumber.value && area_name ? area_name : '地域不明'
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })

    // 電話回線接続
    const connect = async () => {
      if (isIncoming.value && twilioConnection.value) {
        isIncoming.value = false
        twilioConnection.value.accept()
        context.root.$router.push({
          path: '/administration/users/'
        })
        context.root.$store.commit('updatePhoneNumber', {
          phoneNumber: phoneNumber.value
        })
      } else if (/^[\d+\-() ]+$/.test(phoneNumber.value) && device.value) {
        const realPhoneNumber = `+81${phoneNumber.value
          .replace(/[^\d]/g, '')
          .replace(/^0/, '')}`
        const outgoingConnection = await deviceConnect({ To: realPhoneNumber })
        outgoingConnection.on('ringing', () => {
          console.log(`Ringing to ${realPhoneNumber} ...`)
        })
      } else {
        console.log('Something wrong')
      }

      isRecording.value = false
    }

    // 電話回線切断
    const disconnect = () => {
      saveConnection.value = twilioConnection.value
      if (twilioConnection.value) {
        twilioConnection.value.reject()
        twilioConnection.value = null
        isIncoming.value = false
      }
      if (device.value) {
        device.value!.disconnectAll()
        twilioConnection.value = null
        isIncoming.value = false
      }
      if (isRecording.value) {
        isRecording.value = false
        dialog.value = true
      }
      // 2023-08-01 相手先名を非表示
      context.emit('callDisplayPartnerNames', false)
    }

    const isQueued = ref<boolean>(false)
    const isQueuing = ref<boolean>(false)
    const addQueue = () => {
      savePhoneNumber.value = phoneNumber.value
      const callSid = twilioConnection.value!.parameters!.CallSid
      const id = context.root.$auth.user.id
      isQueuing.value = true
      context.root.$axios
        .get(`${TWILIO_QUEUE_URL}?callSid=${callSid}&id=${id}`)
        .then(() => {
          return Promise.resolve()
        })
        .then(() => {
          // 保留成功
          isQueued.value = true
          // ignore new connecting
          return context.root.$store.dispatch('setOperatorAvailability', {
            available: false
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          // ボタンを押せるようにする
          isQueuing.value = false
        })
    }
    const isResuming = ref<boolean>(false)
    const resumeQueue = async () => {
      isResuming.value = true
      const id = context.root.$auth.user.id
      const outgoingConnection = await deviceConnect({
        To: `queue_operator_${id}`,
        queue: 'true',
        id: String(id)
      })
      outgoingConnection.on('ringing', () => {
        console.log(`Ringing to queue ...`)
      })
      phoneNumber.value = savePhoneNumber.value
      isQueued.value = false
    }

    // firestore で availability を保存中は離席ボタンを押せないようにする
    const isSavingOperatorAvailability = ref<boolean>(true)

    const isAvailable = ref<boolean>(true)
    // 有効切り替え
    const toggleAvailability = () => {
      isSavingOperatorAvailability.value = true
      context.root.$store
        .dispatch('setOperatorAvailability', {
          available: !isAvailable.value
        })
        .then(() => {
          setTimeout(() => {
            isSavingOperatorAvailability.value = false
          }, 1000)
        })
    }

    const isRecording = ref<boolean>(false)
    const startRecordEnable = ref<boolean>(true)
    const recordInfo = ref('')
    // 録音開始
    const startRecording = () => {
      startRecordEnable.value = false
      const callSid = twilioConnection.value!.parameters!.CallSid
      isRecording.value = false
      context.root.$axios
        .get(`${TWILIO_RECORDON_URL}?callSid=${callSid}`)
        .then((response) => {
          isRecording.value = true
          console.log('=== recording start success ===')
          console.log(response.data)
          recordInfo.value = response.data.sid
          startRecordEnable.value = true
        })
        .catch((err) => {
          isRecording.value = false
          console.log('*** recording start error ***')
          console.log(err)
          startRecordEnable.value = true
        })
    }

    const dialog = ref<boolean>(false)
    const saveDialog = ref<boolean>(false)
    const voiceName = ref('')
    const isInvalidVoiceName = computed(() => {
      const val = voiceName.value
      if (!val) {
        return true
      }
      return !/^[^.]*$/.test(val)
    })
    const voiceRule = (val: string) => {
      if (!val) {
        return true
      }
      return /^[^.]*$/.test(val) || '拡張子は指定しません'
    }

    // 音声録音保存準備
    const prepareDownload = () => {
      // 処理待機
      isWaitingDownload.value = true

      const id = context.root.$auth.user.id
      const sdt = moment().format('YYYY_MM_DD_HH_mm')
      voiceName.value = sdt + '_' + id + '_' + phoneNumber.value

      // ダウンロードまで時間を待たす
      setTimeout(() => {
        dialog.value = false
        saveDialog.value = true
        phoneNumber.value = ''
        isWaitingDownload.value = false
      }, 5000)
    }

    const cancelEnable = ref<boolean>(true)
    // 音声録音ダウンロードキャンセル
    const cancelDownload = () => {
      execEnable.value = false
      cancelEnable.value = false
      const callSid = saveConnection.value!.parameters!.CallSid
      const recordSid = recordInfo.value
      if (isRecording) {
        context.root.$axios
          .get(
            `${TWILIO_DELETEVOICE_URL}?callSid=${callSid}&recSid=${recordSid}`
          )
          .then((response) => {
            isRecording.value = false
            dialog.value = false
            saveDialog.value = false
            console.log('=== cancel download success ===')
            console.log(response.data)
            execEnable.value = true
            cancelEnable.value = true
          })
          .catch((err) => {
            isRecording.value = false
            dialog.value = false
            saveDialog.value = false
            console.log('*** cancel download error ***')
            console.log(err)
            execEnable.value = true
            cancelEnable.value = true
          })
        saveConnection.value = null
        phoneNumber.value = ''
      }
    }

    const execEnable = ref<boolean>(true)
    // 音声録音ダウンロード実行
    const execDownload = () => {
      execEnable.value = false
      const callSid = saveConnection.value!.parameters!.CallSid
      const recordSid = recordInfo.value
      if (isRecording) {
        context.root.$axios
          .$get(
            `${TWILIO_DOWNLOADVOICE_URL}?callSid=${callSid}&recSid=${recordSid}`,
            {
              responseType: 'blob'
            }
          )
          .then((response) => {
            console.log('=== exec download success ===')
            const blob = new Blob([response], {
              type: 'audio/mpeg'
            })
            const fileName = voiceName.value + '.mp3'
            const navi = window.navigator as any
            if (navi.msSaveOrOpenBlob) {
              // for IE, Edge
              navi.msSaveOrOpenBlob(blob, fileName)
            } else {
              // for Chrome , etc...
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              window.URL.revokeObjectURL(url)
              if (link.parentNode != null) {
                link.parentNode.removeChild(link)
              }
            }
            saveDialog.value = false
            cancelDownload()
            execEnable.value = true
          })
          .catch((err) => {
            console.log('*** exec download error ***')
            console.log(err)
            saveConnection.value = null
            isRecording.value = false
            saveDialog.value = false
            execEnable.value = true
          })
      }
    }

    context.root.$nuxt.$on('callTo', (numberTo: string) => {
      phoneNumber.value = numberTo
    })

    watch(twilioConnection, () => {
      if (isQueued.value || isQueuing.value || device.value == null) {
        return
      }
      context.root.$store.dispatch('setOperatorAvailability', {
        available: !twilioConnection.value
      })
    })

    // firestoreリスナーの作成
    let observer: any = null
    // firestoreのopertators available と同期をとる
    context.root.$store.dispatch('getOperatorDoc').then((doc) => {
      console.log('start listening for firebase changes ...')
      observer = doc.onSnapshot(
        (docSnapshot: any) => {
          /*
          console.log(`${docSnapshot.data().id}`)
          console.log(`${docSnapshot.data().available}`)
          console.log(`${docSnapshot.data().updated_at}`)
          */
          // firestoreの値を反映
          if (docSnapshot.exists) {
            isAvailable.value = docSnapshot.data().available
          }
        },
        (err: any) => {
          console.log(`Encountered error: ${err}`)
        }
      )
    })

    // Twilioオブジェクトの破棄とfirebaseのリスナーのデタッチ
    const disposePhoneHeader = () => {
      if (observer) {
        console.log('stop listening for firebase changes')
        observer()
      }
      if (twilioConnection.value) {
        console.log('twilio connection rejecting...')
        twilioConnection.value!.reject()
        twilioConnection.value = null
      }
      if (device.value) {
        console.log('twilio device deleting...')
        device.value!.disconnectAll()
        device.value!.destroy()
      }
      console.log('twilio object deleted.')
    }

    // let serviceWorker = navigator.serviceWorker.controller
    let serviceWorker: any = false
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker
        .register('/logout-sw.js', { scope: '/' })
        .then((registration) => {
          if (registration.installing) {
            serviceWorker = registration.installing
          } else if (registration.waiting) {
            serviceWorker = registration.waiting
          } else if (registration.active) {
            serviceWorker = registration.active
          }
        })
    }

    window.addEventListener('beforeunload', () => {
      if (serviceWorker) {
        serviceWorker.postMessage({
          user: context.root.$auth.user,
          url: TWILIO_DISABLE_URL
        })
      }
    })

    const isTwilioReady = computed(() => device.value != null)

    const deviceConnect = async (
      options: Record<string, string>
    ): Promise<Call> => {
      const call: Call = await device.value!.connect({ params: options })

      call.on('disconnect', (_call: Call) => {
        console.log('Call ended.')
        if (twilioConnection.value) {
          saveConnection.value = twilioConnection.value
        }
        isIncoming.value = false
        twilioConnection.value = null
        context.root.$store.commit('updatePhoneNumber', {
          phoneNumber: phoneNumber.value
        })
        if (isRecording.value) {
          isRecording.value = false
          dialog.value = true
        } else {
          phoneNumber.value = ''
        }
      })

      call.on('cancel', (_call: Call) => {
        isIncoming.value = false
        twilioConnection.value = null
        // 2023-08-01 相手先名を非表示
        context.emit('callDisplayPartnerNames', false)
      })

      call.on('error', (error: any) => {
        console.log('Twilio.Call Error: ' + error.message)
        if (error.code === 31205 && context.root.$store.state.auth.user) {
          context.root.$axios
            .$get(
              `${TWILIO_TOKEN_URL}?id=${context.root.$store.state.auth.user.id}`
            )
            .then((data) => {
              device.value!.updateToken(data.token)
            })
        }
      })

      // For outgoing calls, the "accept" event is emitted when the Call's media session has finished setting up.
      call.on('accept', () => {
        // console.log(call.parameters)
        twilioConnection.value = call
      })

      // console.log(call)
      return call
    }

    return {
      isIncoming,
      isTalking,
      serviceAreaName,
      phoneNumber,
      isOutgoingCall,
      twilioConnection,
      isInvalidPhoneNumber,
      isAvailable,
      isRecording,
      isQueuing,
      isResuming,
      isQueued,
      disconnect,
      connect,
      addQueue,
      resumeQueue,
      toggleAvailability,
      startRecordEnable,
      startRecording,
      dialog,
      saveDialog,
      voiceName,
      isInvalidVoiceName,
      voiceRule,
      prepareDownload,
      cancelEnable,
      cancelDownload,
      execEnable,
      execDownload,
      isSavingOperatorAvailability,
      disposePhoneHeader,
      autoRecording,
      isWaitingDownload,
      isOutgoingOpened,
      deviceConnect,
      isTwilioReady
    }
  },
  auth: false
})
